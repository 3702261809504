import MLBData from './MLB.json';
import MLSData from './MLS.json';
import NBAData from './NBA.json';
import NFLData from './NFL.json';
import NHLData from './NHL.json';
import OthersData from './OTHERS.json';

export const getTeamsByLeague = (leagueName: string) => {
  const leaguesData = {
    MLB: MLBData,
    NBA: NBAData,
    NFL: NFLData,
    NHL: NHLData,
    MLS: MLSData,
    Others: OthersData,
  };

  return leaguesData[leagueName] as typeof MLBData;
};
