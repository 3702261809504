import { v4 as uuidv4 } from 'uuid';

import Image from 'next/image';

const instructions = [
  {
    id: uuidv4(),
    icon: '/icons/buy-ticket-icon.svg',
    title: 'Choose an event',
    description: 'Numerous choices, right at your fingertips',
  },
  {
    id: uuidv4(),
    icon: '/icons/calendar-icon.svg',
    title: 'Customize your package',
    description: 'Select hotels, flights and/or tickets',
  },
  {
    id: uuidv4(),
    icon: '/icons/suitcase-icon.svg',
    title: 'Book your trip',
    description: 'Quick checkout & competitive prices',
  },
];

export const UsageInstructionsCard = () => {
  return (
    <div className="mx-auto my-0 mt-[-98px] hidden w-full max-w-7xl gap-10 rounded-lg bg-gray-75 bg-opacity-100 px-10 py-8 md:flex">
      {instructions.map((instruction) => (
        <div className="flex w-full max-w-96" key={instruction.id}>
          <Image
            src={instruction.icon}
            alt={instruction.title}
            width={38}
            height={38}
            className="mr-5"
          />
          <div>
            <h2 className="text-lg font-bold text-gray-800">
              {instruction.title}
            </h2>
            <p className="text-sm text-gray-500">{instruction.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
