import { LoggedInUserProps } from '@interfaces/auth';
import { USER_ROLE } from '@utils/auth/roles';
import { v2Links } from '@utils/navigation/links';
import { Search } from '@v2/containers';
import { CurrencyDropdown } from '@v2/containers/CurrencyPicker/CurrencyDropdown';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Close,
  Logo,
  Menu04,
  typographyVariants,
} from '@v2/ui';
import clsx from 'clsx';
import { signOut } from 'next-auth/react';
import Link from 'next/link';
import { useState } from 'react';
import { MobileCollapseSports } from '../MobileCollapseSports/MobileCollapseSports';
import { MobileDashboardSettings } from '../MobileCollapseUserSettings/MobileCollapseUserSettings';
import { MobileCollapseConcertsDropdown } from '../MobilleCollapseConcertsDropdown/MobilleCollapseConcertsDropdown';

interface Props {
  user: LoggedInUserProps | null;
}

export const MobileMenu = ({ user }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuWrapperClasses = clsx(
    'flex flex-col bg-white w-full fixed left-0 top-0 h-screen z-20 md:hidden p-4 overflow-scroll text-gray-800',
    {
      hidden: !isMenuOpen,
    }
  );
  const userLastNameChar =
    user?.lastName && user?.lastName?.length > 0 ? user.lastName[0] : '';

  const canAccessPartner = [
    USER_ROLE.agency,
    USER_ROLE.agencyMember,
    USER_ROLE.individualAgent,
  ].includes(user?.role || USER_ROLE.user);

  return (
    <>
      <div className="flex gap-x-6">
        <div className="flex items-center">
          <Search isMobileSmallVersion />
        </div>
        <button
          className="md:hidden"
          onClick={() => {
            setIsMenuOpen(true);
          }}
        >
          <Menu04 />
        </button>
      </div>
      <div className={menuWrapperClasses}>
        <div className="flex h-16 flex-row items-center justify-between">
          <div className="flex flex-row gap-3">
            <Logo variant="black" />
            <CurrencyDropdown variant="black" />
          </div>
          <div className="flex flex-row gap-6 text-gray-800">
            <Search isMobileSmallVersion />
            <button onClick={() => setIsMenuOpen(false)}>
              <Close />
            </button>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-start justify-between text-gray-800">
          <div className="w-full">
            <MobileCollapseSports />
            <MobileCollapseConcertsDropdown />

            <Link
              target="_blank"
              href={'https://blog.elitesportstours.com'}
              className="flex w-full border-b border-gray-200 py-2 text-start text-base font-bold text-gray-800"
            >
              Blog
            </Link>

            {!user && (
              <Link
                href={v2Links.login}
                className="flex h-14 w-full items-center text-start text-base font-bold text-gray-800"
              >
                Sign in
              </Link>
            )}
            {user && (
              <MobileDashboardSettings
                user={user}
                onCloseMenu={() => setIsMenuOpen(false)}
              />
            )}
          </div>
          {user && (
            <div className="flex w-full flex-row items-center border-t border-gray-200 pt-5">
              <div>
                <Avatar className="mr-3 bg-[#98A2B329] backdrop-blur-sm">
                  <AvatarImage src={user.imageUrl} className="object-cover" />

                  <AvatarFallback
                    className={typographyVariants({
                      size: 'tmd',
                      className: `font-semibold uppercase text-gray-800`,
                    })}
                  >
                    {`${user.firstName[0]} ${userLastNameChar}`}
                  </AvatarFallback>
                </Avatar>
              </div>
              <div className="flex flex-1 flex-col">
                <h2 className="mb-1 font-semibold text-gray-800">
                  {user.firstName}&nbsp;{user.lastName}
                </h2>
                <p className="text-sm font-medium text-gray-500">
                  {user.email}
                </p>
              </div>
              <button
                className="font-semibold text-primary-500"
                onClick={async () => {
                  if (canAccessPartner) {
                    await signOut({ callbackUrl: v2Links.partnersLanding });
                  } else {
                    await signOut({ callbackUrl: v2Links.home });
                  }
                }}
              >
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
