import tevoRoutes from '@config/tevoRoutes';
import { leagues, sports } from '@utils/config/leagues';
import { testSign } from '@utils/x-signature';
import { EventResponse } from '@v2/types';
import { getRandomNumber } from '@v2/utils/random-number';
import { addDays } from 'date-fns';
import format from 'date-fns/format';
import Qs from 'qs';
import { imagePath } from './getEventsByLocation';
import { formatLeagueSlug, getVenueImages } from './getVenueImages';

const tevoSiteUri = process.env.TICKET_EVO_BASE_URL;
const concatUri = (link: string) => tevoSiteUri + link;

const addQuery = (obj: Object) => Qs.stringify(obj);

interface Response {
  current_page: string;
  per_page: string;
  total_entries: number;
  events: EventResponse[];
}

interface GetEventsProps {
  page?: number;
  categoryId: string;
  perPage: number;
}

const getEvents = ({ page = 1, categoryId, perPage }: GetEventsProps) => {
  const tomorrow = format(addDays(new Date(), 1), 'yyyy-MM-dd');
  return testSign.getResponse(
    `${concatUri('/events')}?${addQuery({ page, per_page: perPage, category_id: categoryId, order_by: 'events.popularity_score DESC', 'occurs_at.gte': tomorrow })}`
  );
};

export const getTopSellingEvents = async ({
  withVenueImages = true,
  perPage,
  concerts = false,
}) => {
  const categories = await testSign.getResponse(tevoRoutes.categories);

  let matchedCategories;

  if (concerts) {
    matchedCategories = categories.categories.filter(
      (item) =>
        item.slug.toLowerCase() === 'concerts' ||
        item.parent?.slug.toLowerCase() === 'concerts'
    );
  } else {
    matchedCategories = categories.categories.filter(
      (item) =>
        leagues.includes(item.slug.toLowerCase()) ||
        sports.includes(item.slug.toLowerCase())
    );
  }

  const events = await Promise.all(
    matchedCategories.map((category) =>
      getEvents({ categoryId: category.id, perPage })
    )
  );

  const combineAllEvents = events.reduce((acc, event) => {
    return acc.concat(event.events);
  }, []);

  if (!withVenueImages) {
    return combineAllEvents;
  }

  if (concerts) {
    const formattedEvents = combineAllEvents.map((event) => {
      const randomNumber = getRandomNumber();
      const image = `${imagePath}/rsc-${randomNumber}.jpg`;

      return {
        ...event,
        venue: { ...event?.venue, image },
      };
    });

    return formattedEvents;
  }

  const venueImages = await getVenueImages(combineAllEvents);

  const formattedEvents = combineAllEvents.map((event) => {
    const venueImage = venueImages.find(
      (item) =>
        item.venueId === event.venue?.id &&
        item.league === formatLeagueSlug(event?.category?.slug)
    );
    return {
      ...event,
      venue: { ...event.venue, image: venueImage?.imagePath },
    };
  });

  return formattedEvents.filter(
    (ev) =>
      !ev.name
        .toLowerCase()
        .includes('(includes tickets to all regular season home games)')
  );
};

export const getPopularArtists = async ({
  withVenueImages = true,
  perPage,
}) => {
  const categories = await testSign.getResponse(tevoRoutes.categories);

  const matchedCategories = categories.categories.filter(
    (item) =>
      item.slug.toLowerCase() === 'concerts' ||
      item.parent?.slug.toLowerCase() === 'concerts'
  );

  const events = await Promise.all(
    matchedCategories.map((category) =>
      getEvents({ categoryId: category.id, perPage })
    )
  );

  const combineAllEvents = events.reduce((acc, event) => {
    return acc.concat(event.events);
  }, []);

  if (!withVenueImages) {
    return combineAllEvents;
  }

  const formattedEvents = combineAllEvents
    .map((event) => {
      const randomNumber = getRandomNumber();
      const image = `${imagePath}/rsc-${randomNumber}.jpg`;

      return {
        ...event,
        venue: { ...event?.venue, image },
      };
    })
    .sort((a, b) => b.popularity_score - a.popularity_score);

  return formattedEvents;
};
