const SkeletonSectionTitle = ({ children }) => {
  return (
    <div className="bg-gray-75">
      <p className="px-5 py-3 text-sm font-semibold text-gray-500">
        {children}
      </p>
    </div>
  );
};

const SkeletonSectionItem = () => {
  return (
    <div className="flex space-x-4 p-5">
      <div className="flex w-full">
        <div className="mr-6">
          <div className="mb-1 h-5 w-20 rounded-lg bg-gray-200"></div>
          <div className="h-5 w-14 rounded-lg bg-gray-200"></div>
        </div>
        <div className="w-full">
          <div className="mb-1 h-5 w-full max-w-80 rounded-lg bg-gray-200"></div>
          <div className="h-5 w-48 rounded-lg bg-gray-200"></div>
        </div>
      </div>
    </div>
  );
};

export const SearchSkeleton = () => {
  return (
    <div className="mx-auto w-full">
      <SkeletonSectionTitle>Teams or performer</SkeletonSectionTitle>
      <div className="animate-pulse">
        <SkeletonSectionItem />
        <SkeletonSectionItem />
      </div>

      <SkeletonSectionTitle>Location</SkeletonSectionTitle>
      <div className="animate-pulse">
        <SkeletonSectionItem />
        <SkeletonSectionItem />
        <SkeletonSectionItem />
      </div>
    </div>
  );
};
