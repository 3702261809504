import tevoRoutes from '@config/tevoRoutes';

import { leagues } from '@utils/config/leagues';
import { testSign } from '@utils/x-signature';
import { PerformerResponse } from '@v2/types';
import Qs from 'qs';

const tevoSiteUri = process.env.TICKET_EVO_BASE_URL;
const concatUri = (link: string) => tevoSiteUri + link;

const addQuery = (obj: Object) => Qs.stringify(obj);

interface Response {
  current_page: string;
  per_page: string;
  total_entries: number;
  performers: PerformerResponse[];
}

const getPerformers = ({ page }: { page?: number } = { page: 1 }) => {
  return testSign.getResponse(
    `${concatUri('/performers')}?${addQuery({ page, per_page: 99, order_by: 'performers.popularity_score DESC' })}`
  );
};

const getNumberOfEvents = async (id: number) => {
  const response = await testSign.getResponse(
    tevoRoutes.events({
      page: 1,
      performer_id: id,
    })
  );
  return response.total_entries;
};

export const getPopularPerformers = async () => {
  const [
    firstPagePopularPerformers,
    secondPagePopularPerformers,
    thirdPagePopularPerformers,
  ] = await Promise.all<Response>([
    getPerformers(),
    getPerformers({ page: 2 }),
    getPerformers({ page: 3 }),
  ]);

  const filterPerformers = [
    ...firstPagePopularPerformers.performers,
    ...secondPagePopularPerformers.performers,
    ...thirdPagePopularPerformers.performers,
  ]
    .filter(
      (item) => leagues.includes(item.category.name.toLowerCase()) && item.venue
    )
    .slice(0, 12);

  const performersWithEvents = await Promise.all(
    filterPerformers.map(async (item) => {
      const performer = {
        ...item,
        numberOfEvents: await getNumberOfEvents(item.id),
      };
      return performer;
    })
  );
  return performersWithEvents;
};
