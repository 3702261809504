import { Carousel } from '@v2/components';
import { getTopDestinations } from '@v2/services/getTopDestinations';
import Image from 'next/image';
import Link from 'next/link';

export const DestinationsSection = () => {
  const topDestinations = getTopDestinations();
  return (
    <div>
      <h1 className="mb-6 text-lg font-bold md:text-2xl">Top destinations</h1>
      <Carousel>
        {topDestinations.map(({ id, name, imageUrl, url }) => (
          <Link
            href={url}
            key={id}
            className="flex flex-shrink-0 flex-grow-0 basis-60 flex-col"
          >
            <div className="mb-3 h-40 w-full overflow-hidden rounded-lg">
              <Image
                src={imageUrl}
                alt={name}
                width="0"
                height="0"
                sizes="246px"
                loading="lazy"
                className="h-[160px] w-[246px] rounded-lg object-cover object-center"
              />
            </div>
            <div className="w-full max-w-64">
              <h2 className="mb-2 text-sm font-semibold md:text-base">
                {name}
              </h2>
            </div>
          </Link>
        ))}
      </Carousel>
    </div>
  );
};
