'use client'
import routes from "@config/routes";
import instance from "@instance";
import getCookie from "@utils/get-cookie";
import { setCookie } from "cookies-next";
import Script from "next/script";
import { useEffect, useState } from "react";

export function ClarityContext({ children }: { children: React.ReactNode }) {


  const [anonymousUserId, setAnonymousUserId] = useState('');


  useEffect(() => {
    const ipAddress = getCookie('user-ip') as string;
    if (ipAddress) {
      instance
        .server(routes.createAnonymousUser, {
          body: JSON.stringify({ ipAddress }),
          method: 'POST',
        })
        .then(async (anonymousUser) => {
          const response = await anonymousUser.json();
          setCookie('anonymous-user-id', response.id); // Set the cookie for the anonymous user ID
          setAnonymousUserId(response.id);
        })
        .catch((error) => {
          console.error('Error creating anonymous user:', error);
        });
    }
  }, []);

  useEffect(() => {
    // Wait until the Clarity object is available
    if (typeof window !== "undefined" && anonymousUserId) {
      const checkClarity = setInterval(() => {
        if (window.clarity) {
          // Call Clarity functions here once it's loaded
          window.clarity("set", "anonymousUserId", anonymousUserId);
          clearInterval(checkClarity); // Stop checking once Clarity is loaded
        }
      }, 100); // Check every 100ms

      return () => clearInterval(checkClarity);
    }
  }, [anonymousUserId]);




  return (
    <>


      <Script id="clarity-script" strategy="afterInteractive">
        {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID}");
          `}
      </Script>

      {children}
    </>
  );
}
