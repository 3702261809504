import Image from 'next/image';
import Link from 'next/link';

interface Props {
  title: string;
  date?: string;
  url: string;
  image: string;
}

export const Article = ({ title, date, url, image }: Props) => {
  return (
    <Link
      href={url}
      key={title}
      target="_blank"
      className="`w-full lg:w-[410px]"
    >
      <div className="mb-3 h-40 w-full rounded-lg bg-cover">
        <Image
          src={image}
          alt={title}
          width={410}
          height={160}
          loading="lazy"
          className="h-[160px] w-full rounded-lg object-cover object-center"
        />
      </div>
      <h2 className="mb-2 text-base font-semibold leading-6">{title}</h2>
      {date && (
        <p className="text-sm font-medium leading-5 text-gray-500">{date}</p>
      )}
    </Link>
  );
};
