'use client';

import { useAuthUserType } from '@hooks/use-auth-user-type';
import { TopHeader } from '@v2/components';
import { type PropsWithChildren } from 'react';
import { cn } from '@v2/utils';

export function AuthContainer({ children }: PropsWithChildren) {
  const userType = useAuthUserType();
  return (
    <div
      className={cn(
        'min-h-screen',
        userType === 'agency' ? 'bg-gray-900' : 'bg-white'
      )}
    >
      <TopHeader />
      <div className="mt-10 flex w-full flex-col items-center justify-center sm:mt-12">
        <div className="flex w-full flex-col gap-y-8 px-4 sm:w-[560px] md:px-0">
          {children}
        </div>
      </div>
    </div>
  );
}
