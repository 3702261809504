import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { CalendarIcon } from '@v2/ui/Icon';
import clsx from 'clsx';
import { addMonths, endOfMonth, endOfWeek, endOfYear, format } from 'date-fns';
import { useEffect, useState } from 'react';

enum DateDropdownItems {
  ANY_DATE = 'Any date',
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  NEXT_3_MONTHS = 'Next 3 months',
  NEXT_6_MONTHS = 'Next 6 months',
  THIS_YEAR = 'This year',
}

//EQ("="), GTE(">="), GT(">"), LT("<"), LTE("<=");

const dateRangeByItem = {
  [DateDropdownItems.ANY_DATE]: '',
  [DateDropdownItems.THIS_WEEK]: `${format(endOfWeek(new Date()), 'yyyy-MM-dd')}`,
  [DateDropdownItems.THIS_MONTH]: `${format(endOfMonth(new Date()), 'yyyy-MM-dd')}`,
  [DateDropdownItems.NEXT_3_MONTHS]: `${format(addMonths(new Date(), 3), 'yyyy-MM-dd')}`,
  [DateDropdownItems.NEXT_6_MONTHS]: `${format(addMonths(new Date(), 6), 'yyyy-MM-dd')}`,
  [DateDropdownItems.THIS_YEAR]: `${format(endOfYear(new Date()), 'yyyy-MM-dd')}`,
};

const defaultValue = DateDropdownItems.ANY_DATE;

interface Props {
  onDateChange: (dateRangeFilter: string) => void;
  isSearchResultsOpen?: boolean;
}

export const DateDropdown = ({
  onDateChange,
  isSearchResultsOpen = false,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const triggerClassnames = clsx('', {
    'text-gray-800': selectedValue === defaultValue,
    'text-primary-500': selectedValue !== defaultValue,
  });

  useEffect(() => {
    onDateChange(dateRangeByItem[selectedValue]);
  }, [selectedValue]);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button
          type="button"
          className="hidden h-16 min-w-fit items-center justify-center gap-3 border-r-2 px-5 font-semibold text-gray-800 md:flex"
        >
          <CalendarIcon className={triggerClassnames} />
          {selectedValue}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align={isSearchResultsOpen ? 'end' : 'start'}
          className="mt-4 rounded-xl bg-white py-4 shadow-lg"
        >
          {Object.values(DateDropdownItems).map((item) => (
            <DropdownMenu.Item
              key={item}
              onClick={() => {
                setSelectedValue(item);
              }}
              className="w-56 cursor-pointer px-6 py-3 font-semibold text-gray-800 outline-none hover:bg-gray-100"
            >
              {item}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
