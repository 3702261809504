'use client';
import Image from 'next/image';

const usageSteps = [
  {
    icon: '/icons/buy-ticket-icon.svg',
    title: 'Choose an event',
    description:
      'Look up live events in any city or venue to begin organizing the perfect trip.',
  },
  {
    icon: '/icons/calendar-icon.svg',
    title: 'Build a package',
    description:
      'Get the greatest accommodations, flights and event tickets at affordable prices.',
  },
  {
    icon: '/icons/suitcase-icon.svg',
    title: 'Book your trip',
    description:
      'No more scrambling to plan trips. Keep track of your entire itinerary in a single location.',
  },
];

export const UsageInstructionsSection = () => {
  const scrollToUp = () => {
    document.querySelector('header')?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <section className="mb-10 flex w-full max-w-7xl flex-col border-b border-gray-300 pb-10 md:mb-16 md:flex-row md:pb-20">
      <div className="flex w-full max-w-2xl flex-col items-start justify-center">
        <h1 className="mb-5 w-full max-w-lg text-xl font-bold md:text-4xl">
          Your go-to solution for perfectly planned getaways
        </h1>
        <p className="mb-6 text-sm font-medium text-gray-500 md:mb-10 md:text-lg">
          Find the best deals on hotel & ticket packages.
        </p>
        <button
          onClick={scrollToUp}
          type="button"
          className="flex h-10 w-28 cursor-pointer items-center justify-center rounded-lg bg-red-500 font-semibold text-white md:h-12 md:w-32"
        >
          Search now
        </button>
      </div>
      <div className="mt-10 flex w-full max-w-2xl flex-col gap-10 md:mt-0 md:flex-row">
        {usageSteps.map((step) => (
          <div key={step.title} className="w-full md:max-w-44">
            <Image
              src={step.icon}
              alt={step.title}
              width={45}
              height={45}
              className="mb-5"
            />
            <h3 className="mb-4 text-xl font-bold text-gray-800">
              {step.title}
            </h3>
            <p className="text-base font-medium text-gray-500">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};
