'use client';
import routes from '@config/routes';
import { apiFetcher } from '@instance';
import { AlertToast, AlertTriangle } from '@v2/ui';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useDebounceCallback } from 'usehooks-ts';
import { DesktopSearchInput } from './components/DesktopSearchInput/DesktopSearchInput';
import { MobileSearchInput } from './components/MobileSearchInput/MobileSearchInput';

import devConsole from '@utils/developer-console';
import {
  formatSearchResultData,
  type SearchResultsProps,
} from './services/format-search-result-data';

interface Props {
  isMobileSmallVersion?: boolean;
}

export const Search = ({ isMobileSmallVersion = false }: Props) => {
  const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);
  const [isSearchMobileInputOnFocus, setIsSearchMobileInputOnFocus] =
    useState(false);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultsProps[]>();
  const [searchValue, setSearchValue] = useState('');

  const [occursAtFilter, setOccursAtFilter] = useState('');

  const toggleInputFocus = (newValue?: boolean) => {
    setIsSearchInputOnFocus((oldValue) => {
      if (typeof newValue !== 'undefined') {
        return newValue;
      }
      return !oldValue;
    });
  };

  const toggleMobileInputFocus = () => {
    setIsSearchMobileInputOnFocus((oldValue) => !oldValue);
  };

  const debounceSearch = useDebounceCallback(
    (searchValue: string, occursAtFilter: string) => {
      getAPIData(searchValue, occursAtFilter);
    },
    1000
  );

  const onChangeSearchInput = useCallback(
    (value: string) => {
      if (!value.trimStart().length) setSearchResults([]);
      setSearchValue(value);
      setSearchResults(undefined);
      setIsLoadingResults(false);
      debounceSearch.cancel();
      if (value.trimStart().length > 2) {
        setIsLoadingResults(true);
        debounceSearch(value.trimStart(), occursAtFilter);
      }
    },
    [occursAtFilter]
  );

  const getAPIData = async (search: string, occursAt: string) => {
    setIsLoadingResults(true);
    setSearchResults(undefined);
    try {
      const { results, locations, events } = await apiFetcher(routes.search, {
        body: JSON.stringify({ q: search, occursAt }),
      });

      const formattedSearchResult = formatSearchResultData(
        results,
        locations,
        events
      );

      setSearchResults(formattedSearchResult);
    } catch (e: any) {
      devConsole({ e });
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    } finally {
      setIsLoadingResults(false);
    }
  };

  const onDateFilterChange = useCallback(
    async (dateFilter: string) => {
      setOccursAtFilter(dateFilter);

      if (searchValue.trimStart().length) {
        toggleInputFocus(true);
        await getAPIData(searchValue.trimStart(), dateFilter);
      }
    },
    [searchValue, getAPIData]
  );

  return (
    <>
      <div
        className={isMobileSmallVersion ? 'h-6 md:hidden' : 'mx-4 mb-6 md:mx-0'}
      >
        <DesktopSearchInput
          isSearchInputOnFocus={isSearchInputOnFocus}
          toggleInputFocus={toggleInputFocus}
          searchResults={searchResults}
          inputValue={searchValue}
          isLoadingResults={isLoadingResults}
          onChangeInput={onChangeSearchInput}
          onDateChange={onDateFilterChange}
        />
        <MobileSearchInput
          isSearchMobileInputOnFocus={isSearchMobileInputOnFocus}
          toggleMobileInputFocus={toggleMobileInputFocus}
          searchResults={searchResults}
          inputValue={searchValue}
          isLoadingResults={isLoadingResults}
          onChangeInput={onChangeSearchInput}
          isMobileSmallVersion={isMobileSmallVersion}
        />
      </div>
    </>
  );
};
