import { useDashboardLinks } from '@hooks/use-dashboard-links';
import { LoggedInUserProps } from '@interfaces/auth';
import { ChevronDown, ChevronUp } from '@v2/ui';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';

const Title = ({ title }) => (
  <div className="flex items-center text-gray-800">
    <h3 className="py-4 text-base font-bold text-gray-800">{title}</h3>
  </div>
);

type MobileDashboardSettingsProps = {
  user: LoggedInUserProps | null;
  onCloseMenu: () => void;
};

export const MobileDashboardSettings = (
  props: MobileDashboardSettingsProps
) => {
  const { onCloseMenu, user } = props;
  const pathname = usePathname();
  const allowedSections = useDashboardLinks(user);

  return (
    <>
      {allowedSections.map((item) => (
        <MobileCollapseItem
          key={item.mobileTitle}
          trigger={<Title title={item.mobileTitle} />}
        >
          {item.items.map((item) => {
            const { icon, title, path } = item;
            const Icon = icon;
            const iconStrokeColor = pathname === path ? '#FA2846' : '#667085';
            return (
              <Link
                onClick={onCloseMenu}
                href={path}
                key={path}
                className="flex items-center gap-2 py-4 font-semibold text-gray-800"
                prefetch={false}
              >
                <Icon
                  size={'20'}
                  // viewBox={iconViewBox}
                  pathProps={{ stroke: iconStrokeColor }}
                />
                {item.title}
              </Link>
            );
          })}
        </MobileCollapseItem>
      ))}
    </>
  );
};
interface MobileCollapseItemProps {
  trigger: string | React.JSX.Element;
  children: React.ReactNode;
}

const MobileCollapseItem = ({ trigger, children }: MobileCollapseItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const baseClassName = clsx('flex flex-col w-full', {
    hidden: !isOpen,
  });
  return (
    <>
      <button
        className="flex w-full items-center justify-between text-left"
        onClick={() => setIsOpen((old) => !old)}
      >
        {trigger}
        {isOpen ? <ChevronUp size="24" /> : <ChevronDown size="24" />}
      </button>
      <div className={baseClassName}>{children}</div>
    </>
  );
};
