import getUserSession from '@utils/auth/get-user-session';
import { getPopularPerformers } from '@v2/services/getPopularPerformers';
import { PopularTeamsList } from './PopularTeamsList';

export const PopularTeams = async () => {
  const popularTeams = await getPopularPerformers();
  const user = await getUserSession();

  return (
    <div className="mb-16">
      <h1 className="mb-4 text-lg font-bold md:mb-8 md:text-2xl">
        Popular this month
      </h1>
      <div className="no-scrollbar grid grid-flow-col grid-rows-2 gap-6 overflow-scroll md:grid-rows-3 md:overflow-hidden">
        <PopularTeamsList
          favorites={user?.follow}
          items={popularTeams}
          userToken={user?.token?.accessToken}
        />
      </div>
    </div>
  );
};
