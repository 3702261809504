'use client';

import { useClickAway } from '@uidotdev/usehooks';
import kebabCase from '@utils/kebab';
import { getTeamsByLeague } from '@v2/services/getTeamsByLeague/getTeamsByLeague';
import { ChevronDown } from '@v2/ui';
import { SportsDropdownIcons } from '@v2/ui/Icon/SportsDropdown';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

const leaguesList = ['MLB', 'NBA', 'NFL', 'NHL', 'MLS', 'Others'] as const;
enum LEAGUES_TYPES {
  MLB = 'MLB',
  NBA = 'NBA',
  NFL = 'NFL',
  NHL = 'NHL',
  MLS = 'MLS',
  OTHERS = 'Others',
}

interface Props {
  buttonStyle: string;
  variant: 'black' | 'white';
}

export const SportsDropdown = ({ buttonStyle, variant }: Props) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<LEAGUES_TYPES>(
    LEAGUES_TYPES.MLB
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const teamsList = getTeamsByLeague(selectedMenuItem);

  const dropdownMenuRef = useClickAway<HTMLDivElement>(() => {
    setIsDropdownOpen(false);
  });

  const searchParams = useSearchParams();

  const isSportsDropdownOpen = searchParams?.get('sportsDropdown');

  useEffect(() => {
    if (isSportsDropdownOpen) {
      setIsDropdownOpen(true);
    }
  }, []);

  return (
    <div ref={dropdownMenuRef}>
      <button
        onClick={() => setIsDropdownOpen((oldValue) => !oldValue)}
        className={`flex items-center gap-2 rounded-lg border-2 p-3 ${isDropdownOpen ? buttonStyle : 'border-transparent'}`}
      >
        Sports <ChevronDown stroke={variant} size="20" />
      </button>

      <div className="relative">
        <div
          className={`${isDropdownOpen ? 'flex' : 'hidden'} absolute z-10 mt-3 w-[800px] flex-col rounded-lg bg-white text-gray-800 shadow-lg`}
          ref={dropdownMenuRef}
        >
          <div className="w-full border-b border-gray-200 px-8 pt-5">
            <ul className="flex">
              {leaguesList.map((league) => (
                <li
                  className={`mr-8 pb-5 ${selectedMenuItem === league ? 'border-b-2 border-primary-500' : ''} `}
                  key={league}
                >
                  <button
                    onClick={() => {
                      setSelectedMenuItem(league as LEAGUES_TYPES);
                    }}
                    className={`flex flex-row items-center gap-2 text-base font-semibold ${selectedMenuItem === league ? 'text-primary-500' : ''}`}
                  >
                    <SportsDropdownIcons size="20" league={league} />
                    {league}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="grid w-full grid-flow-row auto-rows-max grid-cols-3 gap-10 p-8 text-left">
            {teamsList.map(({ title, items }) => (
              <div key={title} className="w-full max-w-56">
                <h4 className="mb-2 text-sm text-gray-500">{title}</h4>
                <ul>
                  {items.map((item) => (
                    <li key={item.team}>
                      <Link
                        prefetch={false}
                        href={`/events/${
                          selectedMenuItem === LEAGUES_TYPES.OTHERS
                            ? kebabCase(
                                item.team.startsWith('NCAA')
                                  ? `ncaa mens ${item.team.split(' ')[1]}`
                                  : item.team
                              )
                            : `${item.league?.toLowerCase()}/${kebabCase(item.team)}`
                        }`}
                        className="text-base font-semibold text-gray-800"
                      >
                        {item.team}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          {selectedMenuItem !== LEAGUES_TYPES.OTHERS && (
            <div className="flex items-center justify-center rounded-b-lg bg-gray-200 py-4">
              <Link
                prefetch={false}
                href={`/events/${selectedMenuItem.toLowerCase()}`}
                className="w-full text-center text-gray-800"
              >
                View all {selectedMenuItem} packages
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
