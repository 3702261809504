import { getArtistsByCategory } from '@v2/services/getArtistsByCategory/getArtistsByCategory';
import { ChevronDown, ChevronUp } from '@v2/ui';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import {
  GenreDropdownIcons,
  SONG_CATEGORY,
  genreCategoriesList,
} from '../ConcertsDropdown';

const leaguesList = ['MLB', 'NBA', 'NFL', 'NHL', 'MLS', 'Others'] as const;

const SportsTitle = (
  <h3 className="py-4 text-base font-bold text-gray-800">Concerts</h3>
);

const CategoryTitle = ({
  category,
  label,
}: {
  category: SONG_CATEGORY;
  label: string;
}) => (
  <div className="flex items-center gap-3 text-gray-800">
    <GenreDropdownIcons isSelected={false} size="24" genre={category} />
    <h3 className="py-4 text-base font-bold text-gray-800">{label}</h3>
  </div>
);

export const MobileCollapseConcertsDropdown = () => {
  return (
    <MobileCollapseItem trigger={SportsTitle}>
      {genreCategoriesList.map(({ label, slug, value }) => {
        const teamList = getArtistsByCategory(value);
        return (
          <MobileCollapseItem
            key={value}
            trigger={<CategoryTitle label={label} category={value} />}
          >
            {teamList.map(
              (
                { artist, slug: artistSlug, categoryId, categorySlug },
                index
              ) => (
                <div
                  key={`mobile-${artistSlug}`}
                  className={`mb-8 flex flex-col ${index === 0 ? 'pt-4' : ''}`}
                >
                  <Link
                    className="mb-2 text-sm font-semibold"
                    prefetch={false}
                    href={`/events/${categorySlug}/${artistSlug}?parentId=${categoryId}`}
                  >
                    {artist}
                  </Link>
                </div>
              )
            )}
            <Link
              prefetch={false}
              className="mb-4 w-full rounded-lg border border-gray-300 py-[10px] text-center text-sm font-semibold text-gray-800"
              href={`/concerts`}
            >
              View all concerts
            </Link>
          </MobileCollapseItem>
        );
      })}
    </MobileCollapseItem>
  );
};

interface MobileCollapseItemProps {
  trigger: string | React.JSX.Element;
  children: React.ReactNode;
}

const MobileCollapseItem = ({ trigger, children }: MobileCollapseItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const baseClassName = clsx('flex flex-col w-full', {
    hidden: !isOpen,
  });
  return (
    <>
      <button
        className="flex w-full items-center justify-between text-left"
        onClick={() => setIsOpen((old) => !old)}
      >
        {trigger}
        {isOpen ? <ChevronUp size="24" /> : <ChevronDown size="24" />}
      </button>
      <div className={baseClassName}>{children}</div>
    </>
  );
};
