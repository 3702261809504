import { useClickAway } from '@uidotdev/usehooks';
import { type SearchResultsProps } from '../../services/format-search-result-data';
import { DateDropdown } from '../DateDropdown/DateDropdown';
import { SearchInput } from '../SearchInput/SearchInput';
import { SearchResults } from '../SearchResults/SearchResults';

interface Props {
  isSearchInputOnFocus: boolean;
  toggleInputFocus: (toggle?: boolean) => void;
  searchResults?: SearchResultsProps[];
  onChangeInput: (value: string) => void;
  isLoadingResults: boolean;
  inputValue: string;
  onDateChange: (dateFilter: string) => void;
}

export const DesktopSearchInput = ({
  isSearchInputOnFocus,
  searchResults,
  isLoadingResults,
  inputValue,
  toggleInputFocus,
  onChangeInput,
  onDateChange,
}: Props) => {
  const isDropdownOpen =
    Boolean(isSearchInputOnFocus && searchResults) ||
    isLoadingResults ||
    Boolean(
      inputValue.length > 2 && searchResults?.length && isSearchInputOnFocus
    );

  const dropdownMenuRef = useClickAway<HTMLDivElement>(() => {
    toggleInputFocus(false);
  });

  return (
    <>
      <div
        className={`hidden h-16 items-center bg-white md:flex ${isDropdownOpen ? 'rounded-t-xl rounded-bl-xl' : 'rounded-xl'}`}
      >
        <DateDropdown
          isSearchResultsOpen={isDropdownOpen}
          onDateChange={onDateChange}
        />

        <div className="flex-1" ref={dropdownMenuRef}>
          <SearchInput
            inputValue={inputValue}
            onFocus={() => toggleInputFocus(true)}
            onChangeInput={(e) => {
              onChangeInput(e.target.value);
            }}
            className="flex flex-1 text-gray-500"
          />
          <SearchResults
            isLoading={isLoadingResults}
            isSearchInputOnFocus={isSearchInputOnFocus}
            searchResult={searchResults}
            inputValue={inputValue}
          />
        </div>
      </div>
    </>
  );
};
