interface Props {
  title: string;
  description: string;
}
export const ColumnItem = ({ title, description }: Props) => {
  return (
    <>
      <h4 className="mb-1 text-sm font-semibold text-gray-800 md:text-base">
        {title}
      </h4>
      <p className="text-xs text-gray-500 md:text-sm">{description}</p>
    </>
  );
};
