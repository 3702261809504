import kebabCase from '@utils/kebab';
import { getTeamsByLeague } from '@v2/services/getTeamsByLeague/getTeamsByLeague';
import { ChevronDown, ChevronUp } from '@v2/ui';
import { SportsDropdownIcons } from '@v2/ui/Icon/SportsDropdown';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';

const leaguesList = ['MLB', 'NBA', 'NFL', 'NHL', 'MLS', 'Others'] as const;

const SportsTitle = (
  <h3 className="py-4 text-base font-bold text-gray-800">Sports</h3>
);

const LeagueTitle = ({ league }) => (
  <div className="flex items-center gap-3 text-gray-800">
    <SportsDropdownIcons size="24" league={league} />
    <h3 className="py-4 text-base font-bold text-gray-800">{league}</h3>
  </div>
);

export const MobileCollapseSports = () => {
  return (
    <MobileCollapseItem trigger={SportsTitle}>
      {leaguesList.map((league) => {
        const teamList = getTeamsByLeague(league);
        return (
          <MobileCollapseItem
            key={league}
            trigger={<LeagueTitle league={league} />}
          >
            {teamList.map(({ title, items }, index) => (
              <div
                key={title + league}
                className={`mb-8 flex flex-col ${index === 0 ? 'pt-4' : ''}`}
              >
                <h3 className="mb-2 text-xs font-semibold text-gray-500">
                  {title}
                </h3>
                {items.map((item) => (
                  <Link
                    className="mb-2 text-sm font-semibold"
                    key={item.team}
                    prefetch={false}
                    href={`/events/${item.league ? `${item.league?.toLowerCase()}/` : ''}${kebabCase(
                      item.team.startsWith('NCAA')
                        ? `ncaa mens ${item.team.split(' ')[1]}`
                        : item.team
                    )}`}
                  >
                    {item.team}
                  </Link>
                ))}
              </div>
            ))}
            {league !== 'Others' && (
              <Link
                prefetch={false}
                className="mb-4 w-full rounded-lg border border-gray-300 py-[10px] text-center text-sm font-semibold text-gray-800"
                href={`/events/${league.toLowerCase()}`}
              >
                View all {league} packages
              </Link>
            )}
          </MobileCollapseItem>
        );
      })}
    </MobileCollapseItem>
  );
};

interface MobileCollapseItemProps {
  trigger: string | React.JSX.Element;
  children: React.ReactNode;
}

const MobileCollapseItem = ({ trigger, children }: MobileCollapseItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const baseClassName = clsx('flex flex-col w-full', {
    hidden: !isOpen,
  });
  return (
    <>
      <button
        className="flex w-full items-center justify-between text-left"
        onClick={() => setIsOpen((old) => !old)}
      >
        {trigger}
        {isOpen ? <ChevronUp size="24" /> : <ChevronDown size="24" />}
      </button>
      <div className={baseClassName}>{children}</div>
    </>
  );
};
