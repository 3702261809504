import { SearchMD } from '@v2/ui';
import { ChangeEvent } from 'react';

interface Props {
  className?: string;
  isInputDisabled?: boolean;
  inputValue: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({
  inputValue,
  className,
  onBlur,
  onFocus,
  onChangeInput,
}: Props) => {
  return (
    <div className={className}>
      <SearchMD className="self-center md:ml-6" />
      <input
        value={inputValue}
        className="h-16 flex-1 border-none bg-transparent pl-4 text-base font-semibold text-slate-800 placeholder:font-medium placeholder:text-slate-500 focus:outline-none"
        type="text"
        placeholder="Search event, team or location"
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChangeInput}
      />
    </div>
  );
};
