'use client';
import {
  deleteFollowEvent,
  postFollowEvent,
} from '@requests/get-pages-content/events';
import { v2Links } from '@utils/navigation/links';
import { TeamCard, type Team } from '@v2/components';
import { AlertToast, AlertTriangle } from '@v2/ui';
import { Spinner } from '@v2/ui/Icon/Spinner/Spinner';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import toast from 'react-hot-toast';

export const TeamListItem = ({
  performer,
  userToken,
  isFavorite,
  onFavoriteUpdate,
  onRemoveFavoriteUpdate,
}) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const onFavoriteClick = async (team: Team) => {
    if (!userToken) {
      return router.push(v2Links.login);
    }

    if (isFavorite) {
      return onUnfavoriteClick(team);
    }

    const { id, name } = team;
    const type = 'performer';
    setIsLoading(true);

    const following = await postFollowEvent(
      Number(id),
      'performer',
      name,
      userToken
    );

    if (!following.error) {
      onFavoriteUpdate({ id, type: type.slice(0, 1), name });

      //router.refresh();
    } else {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    }

    setIsLoading(false);
  };

  const onUnfavoriteClick = async (team: Team) => {
    setIsLoading(true);

    const unfollow = await deleteFollowEvent(team.id, 'performer', userToken);

    if (!unfollow.error) {
      onRemoveFavoriteUpdate({
        id: team.id,
        type: 'performer',
        name: team.name,
      });
      //setFollowed(removedFollowList);

      //router.refresh();
    } else {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    }

    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex h-[90px] min-w-72 items-center justify-center rounded-lg border border-gray-200">
          <Spinner />
        </div>
      ) : (
        <TeamCard
          isFavorite={!!isFavorite}
          onFavoriteClick={onFavoriteClick}
          team={{
            ...performer,
            league: performer.category.name,
            url: `/events/${performer.category.slug}/${performer.slug}`,
          }}
        />
      )}
    </>
  );
};
