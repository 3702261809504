import tevoRoutes from '@config/tevoRoutes';
import instance from '@instance';
import { leagues, sports } from '@utils/config/leagues';

import { testSign } from '@utils/x-signature';
import { EventResponse } from '@v2/types';
import { getRandomNumber } from '@v2/utils/random-number';
import Qs from 'qs';
import { formatLeagueSlug, getVenueImages } from './getVenueImages';

const tevoSiteUri = process.env.TICKET_EVO_BASE_URL;
const concatUri = (link: string) => tevoSiteUri + link;

export const imagePath = '/concerts';

const addQuery = (obj: Object) => Qs.stringify(obj);

interface Response {
  current_page: string;
  per_page: string;
  total_entries: number;
  events: EventResponse[];
}

interface Props {
  page?: number;
  perPage?: number;
  lat: number;
  lon: number;
  serverSide?: boolean;
  categoryName?: string;
  withVenueImages?: boolean;
  startDate?: string;
  endDate?: string;
  teamSlug?: string;
  onlyHomeGames?: boolean;
  opponentId?: number;
  concerts?: boolean;
}

const getEvents = async ({ page, lat, lon, perPage = 50, concerts }: Props) => {
  const categories = await testSign.getResponse(tevoRoutes.categories);

  let matchedCategories;

  if (concerts) {
    matchedCategories = categories.categories.filter(
      (item) =>
        item.slug.toLowerCase() === 'concerts' ||
        item.parent?.slug.toLowerCase() === 'concerts'
    );
  } else {
    matchedCategories = categories.categories.filter(
      (item) =>
        leagues.includes(item.slug.toLowerCase()) ||
        sports.includes(item.slug.toLowerCase())
    );
  }

  const events = await Promise.all(
    matchedCategories.map((category) =>
      testSign.getResponse(
        `${concatUri('/events')}?${addQuery({ page, per_page: perPage, lat, lon, category_id: category.id })}`
      )
    )
  );
  return events.reduce((acc, event) => {
    if (!event || !event.events) return acc;
    return acc.concat(event.events);
  }, []);
};

const getEventsClientSide = ({
  page,
  lat,
  lon,
  categoryName,
  perPage,
  startDate,
  endDate,
  teamSlug,
  onlyHomeGames,
  opponentId,
  concerts,
}: Props) => {
  const newData = instance
    .api('/te/v2/event-by-location', {
      body: JSON.stringify({
        page,
        lat,
        lon,
        categoryName,
        perPage,
        startDate,
        endDate,
        teamSlug,
        onlyHomeGames,
        opponentId,
      }),
    })
    .then((res) => res.json())
    .then((data) => {
      if (concerts) {
        const newEvents = data.filter(
          (item) =>
            item.slug.toLowerCase() === 'concerts' ||
            item.parent?.slug.toLowerCase() === 'concerts'
        );

        return newEvents;
      }

      return data;
    })
    .catch();
  //.finally(() => setLoading(false));

  return newData;
};

export const getEventsByLocation = async ({
  serverSide = true,
  withVenueImages = true,
  concerts = false,
  ...props
}: Props) => {
  const eventsList = serverSide
    ? await getEvents({ ...props, concerts })
    : await getEventsClientSide({ ...props, concerts });

  !serverSide && console.log({ eventsList }, 'eventsList');

  if (!withVenueImages) {
    return { events: eventsList.events, total: eventsList.total_entries };
  }

  if (concerts) {
    const formattedEvents = eventsList.map((event) => {
      const randomNumber = getRandomNumber();

      const image = `${imagePath}/rsc-${randomNumber}.jpg`;
      return {
        ...event,
        venue: { ...event?.venue, image },
      };
    });

    return { events: formattedEvents, total: eventsList.length };
  }

  const venueImages = await getVenueImages(eventsList);

  const formattedEvents = eventsList
    .filter(
      (ev) =>
        !ev.name
          .toLowerCase()
          .includes('(includes tickets to all regular season home games)')
    )
    .map((event) => {
      const venueImage = venueImages.find(
        (item) =>
          item.venueId === event?.venue?.id &&
          item.league === formatLeagueSlug(event?.category?.slug)
      );
      return {
        ...event,
        venue: { ...event?.venue, image: venueImage?.imagePath },
      };
    });

  return { events: formattedEvents, total: eventsList.length };
};
