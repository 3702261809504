import { ColumnItem } from './components/ColumnItem/ColumnItem';

interface RowProps {
  title: string;
  description: string;
  className?: string;
}

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-full rounded-lg border-b border-l border-r border-gray-200">
      {children}
    </div>
  );
};

export const Card = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid w-full grid-cols-8 border-t border-gray-200 p-5 md:grid-cols-10">
      {children}
    </div>
  );
};

export const Row = ({ title, description, className }: RowProps) => {
  return (
    <div className={className}>
      <ColumnItem title={title} description={description} />
    </div>
  );
};

export const Header = ({ children }) => {
  return (
    <div className="w-full rounded-t-lg border-l border-r border-t border-gray-200 bg-gray-25 px-5 py-2">
      <p className="text-sm text-gray-500">{children}</p>
    </div>
  );
};

export const Action = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) => {
  return <div className={className}>{children}</div>;
};
