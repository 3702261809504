import { Ticket01 } from '../MapsAndTravel';
import { SVGComponentProps } from '../Template';
import {
  Baseball,
  Basketball,
  Football,
  Hockey,
  Soccerball,
} from './SportsDropdown';
type Props = SVGComponentProps & { league: string };

export const SportsDropdownIcons = ({ league, ...props }: Props) => {
  const iconByLeague = {
    MLB: Baseball,
    NBA: Basketball,
    NFL: Football,
    NHL: Hockey,
    MLS: Soccerball,
    Others: Ticket01,
  };

  const Icon = iconByLeague[league];

  return <Icon {...props} />;
};
